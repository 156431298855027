import { createStore } from 'vuex'

export default createStore({
  state: {
    leftnavshow:1, // [1,2] [显示,隐藏]
    activeNavParent:{
      text:'项目总况',
      path:''
      
    },
    activeNavChild:{
      text:'工程信息',
      path:'/home/index'
    }
  },
  mutations: {
    Setleftnavshow(state,leftnavshow){
      state.leftnavshow = leftnavshow;
    },
    SetactiveNavParent(state,activeNavParent){
      state.activeNavParent = activeNavParent;
    },
    SetactiveNavChild(state,activeNavChild){
      state.activeNavChild = activeNavChild;
    },
  },
  actions: {
  },
  modules: {
  }
})
