<template>
  <div id="app-wrap">
   <router-view/>
  </div>
</template>

<script> 
export default {
  name: 'app' 
}
</script>

<style lang="scss" scoped>
#app-wrap {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
</style>
