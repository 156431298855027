

// 存储localstorage数据
export const setStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
}
// 取出localstorage数据
export const getStorage = (key) => { 
  if(!sessionStorage.getItem(key) ||sessionStorage.getItem(key) == 'undefined'){
    return ""
  }else{
    return JSON.parse(sessionStorage.getItem(key));
  }
}
// 删除localstorage数据
export const removeStorage = (key) => {
  sessionStorage.removeItem(key);
}
export const authentication = {    //开建申请
  credentials:{
      client_id:'1etAvb3tgMFk30uf0abv9Rt',   
      client_secret:'1uHX5q3tgMFk2y1L6g40gY27', 
      grant_type:'client_credentials'
  },
  baseUrl:'https://api.cloud.pkpm.cn',
} 

