import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '@/views/Home.vue'
const routes = [
  {
    path: '/',
    name: 'loginPage',
    component: () => import('@/views/pages/login/loginPage.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/home/index',
    children: [
      {
        path: '/home/index',
        name: 'index',
        component: () => import('@/views/pages/index/index.vue'),
      },
      {
        path: '/home/ImplementStatistical',
        name: 'ImplementStatistical',
        component: () => import('@/views/pages/ImplementStatistical/ImplementStatistical.vue'),
      },
      {
        path: '/home/projects',
        name: 'projects',
        component: () => import('@/views/pages/projects/projects.vue'),
      },
      {
        path: '/home/modelManagement',
        name: 'modelManagement',
        component: () => import('@/views/pages/modelManagement/index.vue'),
      },
      {
        path: '/home/systemManagement/jurisdiction',
        name: 'jurisdiction',
        component: () => import('@/views/pages/systemManagement/jurisdiction.vue'),
      },
      {
        path: '/home/Construction/list',
        name: 'ConstructionList',
        component: () => import('@/views/pages/Construction/list.vue'),
      },
      {
        path: '/home/Construction/userList',
        name: 'ConstructionUserList',
        component: () => import('@/views/pages/Construction/userList.vue'),
      },
      {
        path: '/home/systemManagement/user',
        name: 'user',
        component: () => import('@/views/pages/systemManagement/user.vue'),
      },
      {
        path: '/home/productionManagement/plan',
        name: 'plan',
        component: () => import('@/views/pages/productionManagement/plan.vue'),
      },
      {
        path: '/home/productionManagement/actual',
        name: 'actual',
        component: () => import('@/views/pages/productionManagement/actual.vue'),
      },
      {
        path: '/home/safeManagement/safeDisclose',
        name: 'safeDisclose',
        component: () => import('@/views/pages/safeManagement/safeDisclose.vue'),
      },
      {
        path: '/home/safeManagement/majorHazard',
        name: 'majorHazard',
        component: () => import('@/views/pages/safeManagement/majorHazard.vue'),
      },
      {
        path: '/home/safeManagement/safetyMemorabilia',
        name: 'safetyMemorabilia',
        component: () => import('@/views/pages/safeManagement/safetyMemorabilia.vue'),
      },
      
      {
        path: '/home/qualityManagement/qualityDisclose',
        name: 'qualityDisclose',
        component: () => import('@/views/pages/qualityManagement/qualityDisclose.vue'),
      },
      {
        path: '/home/fileManagement',
        name: 'fileManagement',
        component: () => import('@/views/pages/fileManagement/index.vue'),
      },
      {
        path: '/home/qualityManagement/qualitymajorHazard',
        name: 'qualitymajorHazard',
        component: () => import('@/views/pages/qualityManagement/qualitymajorHazard.vue'),
      },
      {
        path: '/home/qualityManagement/qualityMemorabilia',
        name: 'qualityMemorabilia',
        component: () => import('@/views/pages/qualityManagement/qualityMemorabilia.vue'),
      },
      {
        path: '/home/outstanding/outstanding',
        name: 'outstanding',
        component: () => import('@/views/pages/outstanding/outstanding.vue'),
      },
      {
        path: '/home/decision/qualityMemorabilia',
        name: 'capital',
        component: () => import('@/views/pages/decision/capital.vue'),
      },
      {
        path: '/home/decision/outputValue',
        name: 'outputValue',
        component: () => import('@/views/pages/decision/outputValue.vue'),
      },
      {
        path: '/home/processSmulation/processSimulationList',
        name: 'processSimulationList',
        component: () => import('@/views/pages/processSmulation/processSimulationList.vue'),
      },{
        path: '/home/vrSafetyEducation/index',
        name: 'vrSafetyEducation',
        component: () => import('@/views/pages/vrSafetyEducation/index.vue'),
      },{
        path: '/home/constructionWaste/index',
        name: 'constructionWaste',
        component: () => import('@/views/pages/constructionWaste/index.vue'),
      },{
        path: '/home/refuseClassification/index',
        name: 'refuseClassification',
        component: () => import('@/views/pages/refuseClassification/index.vue'),
      },{
        path: '/home/materialManagement/materialWarehousing',
        name: 'materialWarehousing',
        component: () => import('@/views/pages/materialManagement/materialWarehousing.vue'),
      },{
        path: '/home/materialManagement/materialIssue',
        name: 'materialIssue',
        component: () => import('@/views/pages/materialManagement/materialIssue.vue'),
      },{
        path: '/home/materialManagement/materialPicking',
        name: 'materialPicking',
        component: () => import('@/views/pages/materialManagement/materialPicking.vue'),
      },{
        path: '/home/materialManagement/demobilizationMaterials',
        name: 'demobilizationMaterials',
        component: () => import('@/views/pages/materialManagement/demobilizationMaterials.vue'),
      },{
        path: '/home/settingManagement/materialSettings',
        name: 'materialSettings',
        component: () => import('@/views/pages/settingManagement/materialSettings.vue'),
      },{
        path: '/home/settingManagement/locationStorageYard',
        name: 'locationStorageYard',
        component: () => import('@/views/pages/settingManagement/locationStorageYard.vue'),
      },{
        path: '/home/settingManagement/waterAndElectricity',
        name: 'waterAndElectricity',
        component: () => import('@/views/pages/settingManagement/waterAndElectricity.vue'),
      },{
        path: '/home/settingManagement/givealarmAudio',
        name: 'givealarmAudio',
        component: () => import('@/views/pages/settingManagement/givealarmAudio.vue'),
      },{
        path: '/home/settingManagement/materialCodingRules',
        name: 'materialCodingRules',
        component: () => import('@/views/pages/settingManagement/materialCodingRules.vue'),
      },{
        path: '/home/settingManagement/materialInventoryAlert',
        name: 'materialInventoryAlert',
        component: () => import('@/views/pages/settingManagement/materialInventoryAlert.vue'),
      },{
        path: '/home/settingManagement/taskAlert',
        name: 'taskAlert',
        component: () => import('@/views/pages/settingManagement/taskAlert.vue'),
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
