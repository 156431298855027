import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
// import locale from '../node_modules/element-plus/lib/locale/lang/zh-cn'
import 'element-plus/lib/theme-chalk/index.css' 
import axios from 'axios';
import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'element-plus/lib/theme-chalk/index.css'
import { getStorage } from '@/js/common'
import {} from '@/js/dhtmlxgantt'
import {} from '@/js/ganttApi'
import "dhtmlx-gantt/codebase/locale/locale_cn";
axios.interceptors.request.use(function (config,a,b,c) {
  if(config.url.indexOf("http://192.168.0.143:4410") != -1){
    let token = getStorage('token');
    if (token) {  
        config.headers.token = token    
    }
    
  } 
 
  return config;
}, function (error) { 
  return Promise.reject(error);
});
//验证是否登录，路由拦截
router.beforeEach(async (to, from, next) => {
  let token = getStorage('token');
  let toPath = to.path;
  if (window.__axiosCancelArr && window.__axiosCancelArr.length) {
    window.__axiosCancelArr.forEach((ele, index) => {
      ele.cancel();
      delete window.__axiosCancelArr[index];
    })
  }
  if (toPath === '/' && !token) {
    next();
  }
  if (!token) {
    console.log('未登录')
    next("/")
  }
  next();
})

const vm = createApp(App).use(store).use(router).use(ElementPlus, { locale }).mount('#app')
export default vm
