<template>
  <div id="home">
    <div class="top-c">
      <!-- 顶部信息 -->
      <top />
    </div>
    <div class="bottom-c">
      <div class="left-c noscrollstyle" :class = "{hide:leftnavshow == 2}">
        <!-- 左侧导航 -->
        <leftNav />
      </div>
      <div class="lcb" :class = "{hide:leftnavshow == 2}">
        <div class="lcb-btn" @click="navsoh">
          <img src="@/assets/images/common/lrt.png" alt="">
        </div>
      </div>
      <div class="right-c" :class = "{full:leftnavshow == 2}">
        <div class="nav-text">
          <span>{{activeNavParent.text}}</span>
          <span v-if = "activeNavChild.text"> > {{activeNavChild.text}}</span>
        </div>
        <div class="content-c scroll_style">
          <!-- 内容区域 -->
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, computed } from "vue";
import leftNav from "@/components/leftNav";
import top from "@/components/top";
import {useStore} from 'vuex'
import { getStorage, setStorage } from "@/js/common";
import {useRouter} from 'vue-router'
export default {
  setup() {
    const store = useStore();
    const state = reactive({
      activeNavParent: computed(() => {
        return store.state.activeNavParent;
      }),
      activeNavChild: computed(() => {
        return store.state.activeNavChild;
      }),
      leftnavshow: computed(() => {
        return store.state.leftnavshow;
      }),
    });
    const getcachedata = ()=> { // 设置项目的一些缓存数据
      let cachedata = getStorage("cachedata"); 
      if(cachedata){
        store.commit('SetactiveNavChild',cachedata.SetactiveNavChild)  
        store.commit('SetactiveNavParent',cachedata.SetactiveNavParent)
        if(cachedata.leftnavshow){
          store.commit('Setleftnavshow',cachedata.leftnavshow)
        } 
      }
    };
    const methods = {
      navsoh:()=>{// 
        let leftnavshow = state.leftnavshow == 1?2:1;
        store.commit('Setleftnavshow',leftnavshow);
        let cachedata = getStorage("cachedata") || {};
        cachedata.leftnavshow = leftnavshow;
        setStorage("cachedata",cachedata)
      }
    };

    onMounted(()=>{ 
      getcachedata();

      // router.push('/home/page01');
    }) 
    return {
      ...toRefs(state),
      ...methods,
    };
  },
  components: {
    leftNav: leftNav,
    top: top,
  },
};
</script>
<style lang="scss">
#home {
  height: 100%;
  width: 100%;
  max-width: 100%;
  position: relative;
  .left-c {
    width: 240px;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    overflow-y: auto;
    bottom: 55px;
    &.hide{
      display: none;
    }
  }
  .lcb{
    position:absolute;
    border-top:1px solid #EBEEF5;
    bottom:0;
    height:55px;
    left:0;
    width:240px;
    background: #fff;
    z-index: 9;
    &.hide{
      width: 15px;
      bottom: 30px;
      .lcb-btn{
        width:100%;
        height:100%;
        top:0;
        right:0;
        transform: rotate(180deg);
      }
    }
    .lcb-btn{
      position:absolute;
      width:36px;
      height:36px;
      background: #00b4ff1a;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      cursor: pointer;
      right:20px;
      top:10px;
      img{
        width:16px;
        height:16px;
        object-fit: contain;
        
      }  
    }
  }
  .right-c {
    margin: 0 0 0 240px;
    height: 100%;
    position: relative;
    overflow: hidden;
    &.full{
      margin: 0;

    }
    .nav-text{
      font-size: 14px;
      color:#666;
      height:55px;
      line-height: 55px;
      padding:0 20px;
    }
  }
  .top-c {
    height: 90px;
    background: chartreuse;
  }
  .bottom-c {
    position: absolute;
    top: 90px;
    width: 100%;
    bottom: 0;
    background: #F3F3F3;
  }
  .content-c {
    position: absolute;
    top: 55px;
    bottom: 30px;
    background: #fff;
    overflow-y: auto;
    left: 20px;
    right: 30px;
  }
}
@import "@/assets/style/init.scss";
@import "@/assets/style/gantt.scss";
</style>
