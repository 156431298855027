import axios from 'axios';
import vm from '@/main.js';
import {baseUrl} from '@/js/util.js'
import { MessageBox, getStorage, removeStorage } from "@/js/common";


axios.interceptors.request.use(function (config) {  
    let token = getStorage('token'); 
    // debugger
      if(config.url.indexOf("https://api.cloud.pkpm.cn") == -1){
        if (token) {  
            config.headers.token = token    
        }
      }
   
   
    return config;
  }, function (error) { 
    return Promise.reject(error);
  });

// 获取订单列表
export const PlatformLogin=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/api/v1/PlatformManagement/PlatformLogin`,
        params:datas
    })
}

export const CreatToken=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/api/v1/WeatherForecast/CreatToken`,
        data:datas
    })
}

export const ToLogin=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/api/v1/WeatherForecast/ToLogin`,
        params:datas
    })
}

export const GetTransferData=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Company/GetTransferData`,
        params:datas
    })
}