

// let baseUrl = 'http://192.168.0.143:9011';

// let baseUrl = 'http://api.dzhuango.com:8090'; 


// let baseUrl = 'http://192.168.0.143:5757'; 


// let baseUrl = 'http://192.168.0.143:4410'; 

// 4.0线上 4.0正式
// let baseUrl = 'https://gdapi.hiails.com';

// 工地api 中建三局
// let baseUrl = 'http://zjsjapi.hiaipc.com'; 


// 4.0线上 4.0正式
let baseUrl = VUE_APP_URL.baseUrl;
export {
  baseUrl,
}
