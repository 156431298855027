<template>
  <div id="top">
    <div class="top-wrap"> 
      <div class="navbar">
        <div class="left-menu"> 
          {{ProjectName?ProjectName:''}}后台管理系统
        </div>
        <div class="right-menu un">
          {{Username?Username:''}}
        </div>
        <div class="right-menu ds">  
          {{nowDate?nowDate:''}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { reactive, toRefs, onMounted, computed,onUnmounted } from "vue" 
import {useRouter,useRoute} from 'vue-router'
import {useStore} from 'vuex'
import { setStorage, getStorage, removeStorage } from "@/js/common";
import {GetTransferData} from '@/js/loginApi.js'
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    console.log(route.path)
    const store = useStore();
    const getcachedata = ()=> { // 设置项目的一些缓存数据 
      let cachedata = getStorage("cachedata"); 
      if(cachedata){
        store.commit('SetactiveNavChild',cachedata.SetactiveNavChild)  
        store.commit('SetactiveNavParent',cachedata.SetactiveNavParent) 
      }
    };
    const state = reactive({
      usernames:getStorage('usernames'),
      ProjectName:'',
      Username:'',
      nowDate:'',
      timer:'',
      token:getStorage('token'),

      up:true,
      activeNavParent:computed(()=>{
        return store.state.activeNavParent
      }),
      activeNavChild:computed(()=>{
        return store.state.activeNavChild
      })
    }) 
    const methods = {
      GetTransferData:()=>{
   
        let data={
          token:state.token
        }
        GetTransferData(data).then((res)=>{
          if(res.data.Code==1){
            state.ProjectName=res.data.Data.ProjectName
            state.Username=res.data.Data.Username
            
          }
        })
      },
      currentTime:()=> {
               state.timer= setInterval(()=>{
                 methods.formatDate()
               }, 500);
            },
      formatDate:()=> {
          let date = new Date();
          let year = date.getFullYear(); // 年
          let month = date.getMonth() + 1; // 月
          month = month < 10 ? "0" + month : month; // 如果只有一位，则前面补零
          let day = date.getDate(); // 日
          day = day < 10 ? "0" + day : day; // 如果只有一位，则前面补零
          let week = date.getDay(); // 星期
          let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
          let hour = date.getHours(); // 时
          hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
          let minute = date.getMinutes(); // 分
          minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
          let second = date.getSeconds(); // 秒
          second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
          state.nowDate = `${year}/${month}/${day}    ${hour}:${minute}:${second}`;
         
      },
      exit:()=> {
        setStorage('cachedata','')
        router.replace("/");
    },
      upchange:()=>{
        state.up = !state.up; 
      },
      topnavchange:(item)=>{ 
        if(item.path != route.path){
          router.push(item.path);
        }
      }
    }
    onMounted(()=>{
      // console.log(state.usernames,55555)
      methods.currentTime()
       methods.GetTransferData()
       getcachedata();
    })
    onUnmounted(()=>{
      if(state.timer){

        clearInterval(state.timer)
        console.log('state.timer','销毁')
      }
    })
    return{
      ...toRefs(state),
      ...methods
    }
  },
  components: { 
  
  },
};
</script>
<style lang="scss" scoped>
#top {
  width: 100%;
  position: relative;
  .top-wrap{
    position: absolute; 
    height: 90px;
    background: #00599B;
    top: 0;
    left: 0;
    right: 0;
    .navbar {
      height: 90px; 
      position: relative;
      background: #00599B;
      box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08); 
      box-sizing: border-box;
      .left-menu{
        line-height: 90px;
        font-size: 14px;
        float:left;
        font-size: 24px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 90px;
        padding:0 0 0 34px;
      }
      .right-menu {
        float: right;
        height: 100%;
        position:relative;
        line-height: 90px;
        &.ds{
          font-size: 14px;
          color: #FFFFFF;
        }
        &.un{
          padding:0 70px 0 40px;
          font-size: 16px;
          color: #FFFFFF;
          margin:0 0 0 30px;
          background: rgba(0, 180, 255, 0.1);
          cursor: pointer;
          position:relative;
          &::after{
            content:'';
            width:0;
            height:0;
            border:8px solid transparent;
            border-top:8px solid #fff;
            display: block;
            right: 38px;
            top: 41px;
            position:absolute;
          }
        }
      }
    } 
  }
}


</style>

