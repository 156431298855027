<template>
  <div id="leftNav">
    <div class="tree-c">
      <div
        class="oneblock"
        v-for="(item, index) in navdata"
        :key="index"
        :class="{ down: item.down }"
      >
        <div
          class="title"
          @click="navselect(item)"
          :class="{ active: item.title == activeNavParent.text && item.path }"
        >
          <div class="icon-c nicon" if="item.in">
            <img :src="item.in" alt="" />
          </div>
          <div class="icon-c aicon" if="item.ia">
            <img :src="item.ia" alt="" />
          </div>
          <div class="text-c">{{ item.title }}</div>
          <div class="status-c" v-show="item.children.length">
            <img src="@/assets/images/common/cicondn.png" alt="" class="dn" />
            <img src="@/assets/images/common/ciconda.png" alt="" class="da" />
          </div>
        </div>
        <div class="s-list" v-if="item.children.length">
          <div
            class="one-s"
            v-for="(v, i) in item.children"
            :key="i"
            @click="childrenselect(item.title, v)"
            :class="{
              active:
                v.title == activeNavChild.text &&
                item.title == activeNavParent.text,
            }"
          >
            {{ v.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
export default {
  props: {
    listdata: Array,
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      token: getStorage("token"),
      navdata: getStorage('navdata'),
      navdata02: [
        {
          title: "项目总况",
          path: "",
          down: false,
          in: require("@/assets/images/common/cicon01n.png"),
          ia: require("@/assets/images/common/cicon01a.png"),
          children: [
            {
              title: "工程信息",
              path: "/home/index",
            },
            {
              title: "机具统计",
              path: "/home/ImplementStatistical",
            },
            {
              title: "项目大事记",
              path: "/home/projects",
            },
          ],
        },
        {
          title: "生产管理",
          path: "",
          down: false,
          in: require("@/assets/images/common/cicon02n.png"),
          ia: require("@/assets/images/common/cicon02a.png"),
          children: [
            {
              title: "计划进度",
              path: "/home/productionManagement/plan",
            },
            {
              title: "实际进度",
              path: "/home/productionManagement/actual",
            },
          ],
        },
        {
          title: "安全管理",
          path: "",
          down: false,
          in: require("@/assets/images/common/cicon03n.png"),
          ia: require("@/assets/images/common/cicon03a.png"),
          children: [
            {
              title: "安全交底",
              path: "/home/safeManagement/safeDisclose",
            },
            {
              title: "重大危险源",
              path: "/home/safeManagement/majorHazard",
            },
            {
              title: "安全大事记",
              path: "/home/safeManagement/safetyMemorabilia",
            },
          ],
        },
        {
          title: "质量管理",
          path: "",
          down: false,
          in: require("@/assets/images/common/cicon04n.png"),
          ia: require("@/assets/images/common/cicon04a.png"),
          children: [
            {
              title: "质量交底",
              path: "/home/qualityManagement/qualityDisclose",
            },
            {
              title: "质量样板",
              path: "/home/qualityManagement/qualitymajorHazard",
            },
            {
              title: "质量大事记",
              path: "/home/qualityManagement/qualityMemorabilia",
            },
          ],
        },
        {
          title: "人员管理",
          path: "",
          down: false,
          in: require("@/assets/images/common/cicon05n.png"),
          ia: require("@/assets/images/common/cicon05a.png"),
          children: [
            {
              title: "优秀员工",
              path: "/home/outstanding/outstanding",
            },
          ],
        },
        {
          title: "决策分析",
          path: "",
          down: false,
          in: require("@/assets/images/common/cicon06n.png"),
          ia: require("@/assets/images/common/cicon06a.png"),
          children: [
            {
              title: "产值完成额",
              path: "/home/decision/qualityMemorabilia",
            },
            {
              title: "资金回收额",
              path: "/home/decision/outputValue",
            },
          ],
        },

        {
          title: "模型管理",
          path: "/home/modelManagement",
          down: false,
          in: require("@/assets/images/common/cicon08n.png"),
          ia: require("@/assets/images/common/cicon08a.png"),
          children: [],
        },
        {
          title: "施工单位管理",
          path: "",
          down: false,
          in: require("@/assets/images/common/cicon07n.png"),
          ia: require("@/assets/images/common/cicon07a.png"),
          children: [
            {
              title: "施工单位列表",
              path: "/home/Construction/list",
            },
          ],
        },
        {
          title: "系统管理",
          path: "",
          down: false,
          in: require("@/assets/images/common/cicon07n.png"),
          ia: require("@/assets/images/common/cicon07a.png"),
          children: [
            {
              title: "权限设置",
              path: "/home/systemManagement/jurisdiction",
            },
            {
              title: "用户设置",
              path: "/home/systemManagement/user",
            },
          ],
        },
      ],
      activeNavParent: computed(() => {
        return store.state.activeNavParent;
      }),
      activeNavChild: computed(() => {
        return store.state.activeNavChild;
      }),
    });
    const setnav = (a, b) => {
      let cachedata = getStorage("cachedata") || {};
      cachedata.SetactiveNavChild = a;
      cachedata.SetactiveNavParent = b;
      setStorage("cachedata", cachedata);
    };
    const methods = {
 
      navlist: (item) => {
        let cachedata = getStorage("cachedata") || {};

        state.navdata.forEach((item, index) => {
          if (
            cachedata.SetactiveNavParent &&
            cachedata.SetactiveNavParent.text == item.title
          ) {
            item.down = true;
          }
        });
      },
      navselect: (item) => {
        console.log(state.navdata, item, 231);
        // 一级菜单点击
        if (item.children.length) {
          // 有子菜单 一级点击只作为展开收起功能
          item.down = !item.down;
        } else {
          // 无子菜单 一级点击 作为选中功能
          if (item.path) {
            // 如有path则具有跳转功能
            store.commit("SetactiveNavChild", {
              text: "",
              path: "",
            });
            store.commit("SetactiveNavParent", {
              text: item.title,
              path: item.path,
            });
            setnav(
              {
                text: "",
                path: "",
              },
              {
                text: item.title,
                path: item.path,
              }
            );
            router.push(item.path);
          }
        }
      },
      childrenselect: (ptitle, child) => {
        // 二级菜单点击 选中二级菜单 和 二级菜单所属的一级菜单

        if (child.path) {
          store.commit("SetactiveNavChild", {
            text: child.title,
            path: child.path,
          });
          store.commit("SetactiveNavParent", {
            text: ptitle,
            path: "",
          });
          setnav(
            {
              text: child.title,
              path: child.path,
            },
            {
              text: ptitle,
              path: "",
            }
          );
          if (child.path) {
            // 如有path则具有跳转功能
            router.push(child.path);
          }
        }
      },
    };
    onMounted(() => {
      methods.navlist();
    });
    return {
      ...toRefs(state),
      ...methods,
    };
  },
  components: {},
};
</script>
<style lang="scss" scoped>
#leftNav {
  width: 100%;
  position: relative;
  .tree-c {
    .oneblock {
      .title {
        position: relative;
        height: 56px;
        line-height: 56px;
        color: #000;
        font-size: 16px;
        box-sizing: border-box;
        padding: 0 0 0 46px;
        border-right: 4px solid transparent;
        cursor: pointer;
        .nicon {
          display: block;
        }
        .aicon {
          display: none;
        }
        .dn {
          display: block;
        }
        .da {
          display: none;
        }
        &:hover {
          background: rgba(0, 131, 255, 0.1);
          .nicon {
            display: none;
          }
          .aicon {
            display: block;
          }
          .dn {
            display: none;
          }
          .da {
            display: block;
          }
        }
        &.active {
          border-right: 4px solid #0083ff;
          background: rgba(0, 131, 255, 0.1);
          .nicon {
            display: none;
          }
          .aicon {
            display: block;
          }
          .dn {
            display: none;
          }
          .da {
            display: block;
          }
          // background: rgba(0, 131, 255, 0.1);
        }
        .status-c {
          position: absolute;
          width: 15px;
          height: 15px;
          top: 50%;
          right: 20px;
          transform: translate(0, -59%) rotate(-90deg);
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .icon-c {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translate(0, -50%);
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
      &.down {
        .s-list {
          display: block;
        }
        .title {
          .status-c {
            transform: translate(0, -59%) rotate(0deg);
          }
        }
      }
      .s-list {
        display: none;
        .one-s {
          font-size: 16px;
          color: #000000;

          box-sizing: border-box;
          height: 50px;
          line-height: 50px;
          padding: 0 0 0 46px;
          cursor: pointer;
          border-right: 4px solid transparent;
          &.active {
            background: rgba(0, 131, 255, 0.1);
            border-right: 4px solid #0083ff;
            color: #0083ff;
          }
        }
      }
    }
  }
}
</style>
